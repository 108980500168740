import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const PlanDetails = () => {
  const { id } = useParams();
  const [detail, setdetail] = useState();
  const history = useHistory();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    loader(true);
    ApiClient.get(`subscription-plan?id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res.data);
      }
      loader(false);
    });
  };

  return (
    <Layout>
      <div class="rowAdd d-flex align-items-center justify-content-between gap-1">
        {" "}
        <span class="d-flex gap-1">
          {" "}
          <a         onClick={(e) => {
              history.goBack();
            }}>
            <i
              title="Back"
              aria-hidden="true"
              class=" fa fa-angle-double-left"
            ></i>
          </a>
          <h2 class="name_heading mb-0">Plan Detail</h2>{" "}
        </span>{" "}
        <a      onClick={(e) => {
              history.push(`/plan/edit/${id}`);
            }}>
          <span title="Edit" class="material-icons text-white">
            border_color
          </span>
        </a>
      </div>
      
      <div className="form-row mt-4">
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Name</label>
       
          <div class="profiledetailscls">{detail?.name}</div>
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Description</label>
       
       <div class="profiledetailscls"> {detail?.description}</div>
      
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Status</label>
       
       <div class="profiledetailscls"> {detail?.status}</div>
        
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Date</label>
       
       <div class="profiledetailscls"> {moment(detail?.createdAt).format("DD MMM YYYY")}</div>
    
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Static QR Code</label>
       
       <div class="profiledetailscls"> {detail?.static_qr_codes}</div>
    
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Dynamic QR Code</label>
       
       <div class="profiledetailscls"> {detail?.dynamic_qr_code}</div>
  
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Scans(per Month)</label>
       
       <div class="profiledetailscls">{detail?.scans_per_month}</div>

        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Users</label>
       
       <div class="profiledetailscls">{detail?.users}</div>

        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Trial Period Days</label>
       
       <div class="profiledetailscls">{detail?.trial_period_days}</div>
      
        </div>
      </div>
      <div className="row mt-2 px-2">
        <h3 className="mb-2">Pricing</h3>
        <div className="col-md-6 mb-3">
          <label className="d-inline-block w-100">
            {detail?.pricing.map((item) => {
              return (
                <div>
                  <label className="mr-2d-inline-block w-50">
                    <b>Month:</b> {item?.interval_count}
                  </label>
                  <label>
                    <b>Amount:</b> {item?.unit_amount}{" "}
                  </label>
                </div>
              );
            })}
          </label>
        </div>
      </div>
      {/* <div className="row">
                <h3 className="mb-2">Features</h3>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_dynamic_qr_code} readOnly className="ch3" /> <b>Unlimited Dynamic Qr Code</b></label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_scans} readOnly className="ch3" /> <b>Unlimited Scans</b></label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_static_qr_code} readOnly className="ch3" /> <b>Unlimited Static Qr Code</b></label>
                </div>
                <div className="col-md-6 mb-2">
                    <label><input type='checkbox' checked={detail?.unlimited_users} readOnly className="ch3" /> <b>Unlimited Users</b></label>
                </div>
            </div> */}
    </Layout>
  );
};

export default PlanDetails;
