import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../methods/api/apiClient';
import './style.scss';
import loader from '../../methods/loader';
import Html from './html';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { search_success } from '../../actions/search';

const Users = (p) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({ page: 1, count: 20, search: '' });
  const [archivedDatafilters, setArchivedDataFilter] = useState({ page: 1, count: 20, search: '' ,isDeleted:true});
  const [data, setData] = useState([]);
  const [archivedData,setarchivedData]  = useState([]);
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
      getArchivedData({search: searchState.data, page: 1})
    }
  }, [searchState]);

  const getData = (p = {}) => {
    setLoader(true);
    let filter = { ...filters, ...p };
    ApiClient.get(`users/list`, filter).then((res) => {
      if (res.success) {
        setData(res.data);
        setTotal(res.total);
      }
      setLoader(false);
    });
  };

  const getArchivedData = (p = {}) => {
    setLoader(true);
    let filter = { ...archivedDatafilters, ...p };
    ApiClient.get(`users/list`, filter).then((res) => {
      if (res.success) {
        setarchivedData(res.data);
        setTotal(res.total);
      }
      setLoader(false);
    });
  };

  const clear = () => {
    setFilter({ ...filters, search: '', page: 1 });
    getData({ search: '', page: 1 });
    getArchivedData({ search: '', page: 1 })
  };

  const deleteUser = (id) => {
    loader(true);
    ApiClient.delete('remove/user', { id: id }).then((res) => {
      if (res.success) {
        toast.success(res.message)
        clear();
        document.getElementById('CloseDeleteModal').click()
      }
      loader(false);
    });
  };

  const Restore = (id) => {
    loader(true);
    ApiClient.put('edit/profile', { id: id , isDeleted:false}).then((res) => {
      if (res.success) {
        toast.success(res.message)
        clear();
        document.getElementById('CloseDeleteModal').click()
      }
      loader(false);
    });
  };

  const deleteArchiveUser = (id) => {
    loader(true);
    ApiClient.delete('destroy', { id: id }).then((res) => {
      if (res.success) {
        toast.success(res.message)
        clear();
        document.getElementById('CloseDeleteModal').click()
      }
      loader(false);
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  };

  const view = (id) => {
    history.push('/usersdetail/' + id);
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    history.push(url);
  };

  const reset = () => {
    let filter = {
      status: '',
      page: 1,
    }
    setFilter({ ...filters, ...filter })
    getData({ ...filter })
    dispatch(search_success(''))
  };

  const statusChange = (itm) => {
    let status = 'active';
    if (itm.status == 'active') status = 'deactive';
    if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this user`)) {
      loader(true);
      ApiClient.put(`change/status?model=users&status=${status}&id=${itm?.id}`).then((res) => {
        if (res.success) {
          getData();
        }
        loader(false);
      }
      );
    }
  };

  return (
    <>
      <Html
        reset={reset}
        view={view}
        edit={edit}
        ChangeStatus={ChangeStatus}
        statusChange={statusChange}
        pageChange={pageChange}
        deleteUser={deleteUser}
        filters={filters}
        loaging={loaging}
        data={data}
        total={total}
        searchState={searchState.data}
        archivedData={archivedData}
        deleteArchiveUser={deleteArchiveUser}
        Restore={Restore}
      />
    </>
  );
};

export default Users;
