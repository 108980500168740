import React, { useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import methodModel from '../../methods/methods';
import moment from 'moment';
import { Link } from 'react-router-dom';

const Html = ({
    edit,
    reset,
    Restore,
    pageChange,
    deleteUser,
    filters,
    loaging,
    data,
    archivedData,
    statusChange,
    deleteArchiveUser,
    searchState,
    view,
    total = { total }
}) => {
    const [deleteId, setdeleteId] = useState();
    const [activeTab, setActiveTab] = useState('users');

    const renderTableData = (userData) => {
        return userData.map((itm, i) => (
            <tr className='data_row' key={itm.id}>
                <td className='table_dats' onClick={() => view(itm.id)}>
                    <img src={methodModel.userImg(itm.image)} className="user_imgs" width='50px' height='50px' />
                </td>
                <td className='table_dats'>{methodModel.capitalizeFirstLetter(itm?.firstName) || "--"}</td>
                <td className='table_dats'>{itm?.email}</td>
                <td className='table_dats'>{itm?.mobileNo ? "+" + itm?.mobileNo : "--"}</td>
                <td className='table_dats'>{moment(itm?.createdAt).format('DD MMM YYYY')}</td>
                <td className='table_dats'>
                    <div className={`user_hours ${itm.status}`} onClick={() => statusChange(itm)}>
                        <span className='contract text-capitalize pointer'>
                            {itm.status === 'deactive' ? 'inactive' : 'active'}
                        </span>
                    </div>
                </td>
                <td className='table_dats'>
                    <div className="action_icons">
                        <a className='edit_icon pointer' title="View" onClick={() => view(itm.id)}>
                            <i className="material-icons view" title="View">visibility</i>
                        </a>
                        {activeTab === 'users' &&<a className='edit_icon pointer' title="Edit" onClick={() => edit(itm.id)}>
                            <i className="material-icons edit" title="Edit">edit</i>
                        </a>}
                        {activeTab != 'users' && <a className='edit_icon pointer' title="Restore" onClick={() => Restore(itm.id)}>
                            <i className="material-icons edit" title="Restore">edit</i>
                        </a>}
                        <span className='edit_icon pointer' onClick={() => setdeleteId(itm.id)} data-toggle="modal" data-target="#exampleModal">
                            <i className="material-icons delete text-danger" title='Delete'> delete</i>
                        </span>
                    </div>
                </td>
            </tr>
        ));
    };

    return (
        <Layout>
            <div className="d-flex justify-content-between align-items-center">
                <h3 className="hedding">Users</h3>
                <article className="d-flex mb-2">
                    <Link to={`/users/add`} className='btn btn-primary ml-2'>Add User</Link>
                    {(filters.status || searchState) && (
                        <a className="btn btn-secondary ml-2 text-light" onClick={reset}>
                            Reset
                        </a>
                    )}
                </article>
            </div>

            <div className="tabs">
                <button
                    className={`tab ${activeTab === 'users' ? 'active' : ''}`}
                    onClick={() => setActiveTab('users')}
                >
                    Users
                </button>
                <button
                    className={`tab ${activeTab === 'archived' ? 'active' : ''}`}
                    onClick={() => setActiveTab('archived')}
                >
                    Archived Users
                </button>
            </div>


            <div className="table-responsive table_section mt-1">
                <table className="table table-striped">
                    <thead className='table_head'>
                        <tr className='heading_row'>
                            <th scope="col" className='table_data pointer'>Image</th>
                            <th scope="col" className='table_data pointer'>Name</th>
                            <th scope="col" className='table_data pointer'>Email</th>
                            <th scope="col" className='table_data pointer'>Mobile Number</th>
                            <th scope="col" className='table_data pointer'>Date</th>
                            <th scope="col" className='table_data pointer'>Status</th>
                            <th scope="col" className='table_data'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loaging && (activeTab === 'users' ? renderTableData(data) : renderTableData(archivedData))}
                    </tbody>
                </table>
            </div>

            {!loaging && total === 0 ? <div className="py-3 text-center">No Data</div> : null}
            {
                !loaging && total > filters.count ? <div className='paginationWrapper'>
                    <span>Showing {data?.length} from {total} records</span>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : null
            }
            {loaging && <div className="text-center py-4">
                <img src="/assets/img/loader.gif" className="pageLoader" />
            </div>}

            {/* Delete Modal */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" id="CloseDeleteModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='text-center mt-4'>
                                <p>Are you sure you want to delete user?</p>
                                <button type="button" className="btn btn-danger" onClick={activeTab === 'archived' ? () => deleteArchiveUser(deleteId) : () => deleteUser(deleteId)}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Html;
