import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { search_success } from "../../actions/search";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Html from "./html";

const QrcodeType = () => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const searchState = useSelector((state) => state.search)
    const [data, setdata] = useState()
    const [filter, setfilter] = useState({ page: 1, count: 20, search: '', status: '', category_id: '' })
    const [loading, setloading] = useState(false)
    const [total, settotal] = useState(0)
    const [category, setcategory] = useState()
    const [deleteId, setdeleteId] = useState()
    const history = useHistory()
    const dragItem = useRef();
    const dragItems = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        getdata()
        getCategory()
    }, [])

    useEffect(() => {
        if (user && user.loggedIn) {
            setfilter({ ...filter, search: searchState.data });
            getdata({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const getdata = (p = {}) => {
        let fil = { ...filter, ...p, sortBy: 'srNumber asc' }
        setloading(true)
        ApiClient.get(`qrtype/all`, fil).then(res => {
            if (res.success) {
                setdata(res.data)
                settotal(res.total)
            }
            setloading(false)
        })
    }

    const getCategory = () => {
        ApiClient.get(`common-category/all?type=qrcode`).then(res => {
            if (res.success) {
                setcategory(res.data.map(item => {
                    return ({ id: item?._id, name: item?.name })
                }))
            }
        })
    }

    const editType = (id) => {
        history.push(`/qrtypes/edit/${id}`)
    }

    const deleteType = (id) => {
        ApiClient.delete(`qrtype?id=${id}`).then(res => {
            if (res.success) {
                toast.success(res.message)
                getdata()
                document.getElementById('CloseDeleteModal').click()
            }
        })
    }

    const statusChange = (item) => {
        let status = 'active';
        if (item.status == 'active') status = 'deactive';
        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            loader(true);
            ApiClient.put(`change/status?model=qrcodetypes&status=${status}&id=${item?.id}`).then((res) => {
                if (res.success) {
                    toast.success(res.message)
                    getdata();
                }
                loader(false);
            }
            )
        }
    }

    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getdata({ page: e });
    }

    const handleFilters = (p = {}) => {
        setfilter({ ...filter, ...p })
        getdata(p)
    }

    const clearAllFilters = () => {
        let p = { page: 1, count: 20, search: '', status: '', category_id: '' }
        setfilter({ ...filter, ...p })
        dispatch(search_success(''))
        getdata(p)
    }

    const handleExport = async () => {
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}qrtype/all?status=${filter?.status}&category_id=${filter?.category_id}&is_export=Y`,
            responseType: "blob",
            body: { token: token }
        })
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `QR Code Types.xlsx`;
        link.click();
    }

    // Drag & Drop 
    const dragStart = (e, position) => {
        dragItem.current = position;
    };
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        const copyListItems = [...data];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItems.current = copyListItems
    };
    const drop = (e) => {
        const copyListItems = [...data];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setdata([...copyListItems]);

        let orderData=copyListItems.map((item,i)=>{
            return {
                id:item?.id,
                srNumber:i,
                type: item?.type
            }
        })
        loader(true)
        ApiClient.put(`qrtype/srNumber`,orderData).then(res=>{
            if(res.success){
                toast.success('Order Updated.')
            }
            loader(false)
        })
    };

    return (
        <>
            <Html
                data={data}
                editType={editType}
                deleteType={deleteType}
                statusChange={statusChange}
                loading={loading}
                total={total}
                filter={filter}
                pageChange={pageChange}
                handleFilters={handleFilters}
                category={category}
                clearAllFilters={clearAllFilters}
                handleExport={handleExport}
                setdeleteId={setdeleteId}
                deleteId={deleteId}
                dragStart={dragStart}
                dragEnter={dragEnter}
                drop={drop}
            />
        </>
    )
}
export default QrcodeType