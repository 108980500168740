let host = document.location.host;
let apiUrl = 'http://portal.jcsoftwaresolution.in:6056/'

if (host.includes('localhost')) {
  // apiUrl = 'http://portal.jcsoftwaresolution.in:6056/'
  // apiUrl = 'http://66.179.251.9:6056/'
  apiUrl = 'https://api.qrcode-bubble.com/'
}else if (host.includes('qradmin.jcsoftwaresolution.in')){
  apiUrl = 'https://qrapi.jcsoftwaresolution.in/'
}else{
  // apiUrl = 'http://portal.jcsoftwaresolution.in:6056/'
  apiUrl = 'https://api.qrcode-bubble.com/'
}

const environment = {
  api: apiUrl,
  tinymceApiKey: '8ybqyx68qvr5hj9sswwzpsjpst6g5kwh31mnun6y0mlx2h09',
};
export default environment;
