import React, { useState, useEffect } from "react";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../../methods/methods";
import { userType } from "../../../models/type.model";
import Html from "./Html";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddEditUser = () => {
  const user = useSelector((state) => state.user);
  const { id } = useParams();
  const [images, setImages] = useState({ image: "" });
  const defaultvalue = userType;
  const [form, setform] = useState({});
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false });
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();
  const [detail, setDetail] = useState();
  const [Err, setErr] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if(Err) return
    let method = "post";
    let url = "add/user";
    let value = {
      ...form,
      ...images,
      role: "user",
    };

    if (form.id) {
      method = "put";
      url = "edit/profile";
      value = {
        id: form?.id,
        fullName: form?.firstName,
        firstName: form?.firstName,
        image: images?.image,
        email: form?.email,
        mobileNo: form?.mobileNo,
        dialCode: form?.dialCode,
      };
      //   delete value?.password;
    } else {
      delete value.id;
    }
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        setTimeout(() => {
          toast.success(res.message);
        }, 100);
        history.push("/users");
      }
      loader(false);
    });
  };

  const imageResult = (e, key) => {
    images[key] = e.value;
    setImages(images);
  };

  const addressResult = (e) => {
    setform({ ...form, address: e.value });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    setSubmitted(false);
    if (id) {
      loader(true);
      ApiClient.get("user/detail", { id }).then((res) => {
        if (res.success) {
          let value = res.data;
          setDetail(value);
          let payload = defaultvalue;
          let oarr = Object.keys(defaultvalue);
          oarr.map((itm) => {
            payload[itm] = value[itm] || "";
          });
          payload.role = value.role._id;
          payload.password = "********";
          setform({ ...payload });
          images.image = payload?.image;
          setImages(images);
        }
        loader(false);
      });
    }
  }, [id]);

  const emailValidation = (email) => {
    const regex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    if (!email || regex.test(email) === false) {
      return false
    } else {
      return true
    }
  }

  const handleEmailValidation = (e) => {
    if (!emailValidation(e.target.value)) {
      setErr(true)
    } else { setErr(false) }
    setform({ ...form, email: e.target.value })
  }

  return (
    <>
      <Html
        form={form}
        detail={detail}
        back={back}
        setEyes={setEyes}
        eyes={eyes}
        setform={setform}
        submitted={submitted}
        images={images}
        addressResult={addressResult}
        handleSubmit={handleSubmit}
        imageResult={imageResult}
        handleEmailValidation={handleEmailValidation}
        Err={Err}
      />
    </>
  );
};

export default AddEditUser;
