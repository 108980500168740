import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import Pagination from "react-pagination-js";
import ApiClient from "../../methods/api/apiClient";
import moment from "moment";
import { toast } from "react-toastify";
import { Tooltip } from 'antd';

const WithdrawRequests = () => {
    const [data, setdata] = useState([]);
    const [filter, setfilter] = useState({ page: 1, count: 20 });
    const [total, settotal] = useState(0);
    const [loading, setloading] = useState(false);

    useEffect(() => {
        getdata();
    }, []);

    const getdata = (p = {}) => {
        let fil = { ...filter, ...p };
        setloading(true);
        ApiClient.get(`withdrawEarning`, fil).then(res => {
            if (res.success) {
                setdata(res?.data?.data);
                settotal(res?.data?.total);
            }
            setloading(false);
        });
    };

    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getdata({ page: e });
    };

    const statusChange = (item) => {
        if (item?.status === 'completed') {
            return;
        }
        let payload = {
            id: item?.id,
            status: 'completed'
        };
        if (window.confirm('Do you really want to change this status to completed?')) {
            ApiClient.put(`status/change`, payload).then(res => {
                if (res.success) {
                    toast.success(res.message);
                    getdata();
                }
            });
        }
    };

    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            toast.success('URL copied to clipboard!');
        }).catch(err => {
            toast.error('Failed to copy URL!');
        });
    };

    return (
        <>
            <Layout>
                <div className="">
                    <h3 className="hedding">Withdraw Requests</h3>

                    <div className="table-responsive mt-3">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Url</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{item?.user_details?.fullName}</td>
                                            <td
                                                onClick={() => copyToClipboard(item?.url)}
                                                className="pointer"
                                                style={{ cursor: 'pointer' }}
                                                data-tip={item?.url}
                                            >
                                                <Tooltip placement="top" title="Click To Copy">
                                                    <span>
                                                        {item?.url}
                                                    </span>
                                                </Tooltip >
                                            </td>
                                            <td>{moment(item?.date_of_withdrawal).format('MMM DD, YYYY hh:mm A')}</td>
                                            <td>$ {item?.withdraw_amount}</td>
                                            <td>
                                                <div className={`user_hours ${item.status === 'pending' ? 'pointer' : ''}`} onClick={() => statusChange(item)}>
                                                    <span className={`contract text-capitalize ${item.status}`}>
                                                        {item.status}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {!loading && total === 0 ? <div className="py-3 text-center">No Data</div> : null}
                    {
                        !loading && total > filter.count ? (
                            <div className='paginationWrapper'>
                                <span>Show {filter.count} from {total} records</span>
                                <Pagination
                                    currentPage={filter.page}
                                    totalSize={total}
                                    sizePerPage={filter.count}
                                    changeCurrentPage={pageChange}
                                />
                            </div>
                        ) : null
                    }
                    {loading ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : null}
                </div>
            </Layout>
        </>
    );
};

export default WithdrawRequests;
