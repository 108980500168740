import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import methodModel from '../../../methods/methods';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const UserDetail = (p) => {
  const { id } = useParams();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState();
  const [Type, setType] = useState([]);
  const [show, setShow] = useState(false);
  const [qrData, setQrData] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrData);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const copyLinksToClipboard = (data) => {
    navigator.clipboard.writeText(data);
    setCopied(true);
  };

  const GetType = () => {
    ApiClient.get("qrtype/all").then((res) => {
      setType(res.data);
    });
  };

  const totalScansCount = (data) => {
    let value = 0;
    if (data?.length != 0) {
      data?.map((item) => {
        value += item?.count;
      });
      return value;
    } else {
      return value;
    }
  };

  const getDetail = () => {
    loader(true);
    ApiClient.get(`user/detail`, { id: id }).then((res) => {
      if (res.success) {
        setData(res.data);
      }
      loader(false);
    });
  };

  const back = () => {
    history.goBack();
  };

  useEffect(() => {
    getDetail();
    GetType();
  }, []);

  const FindType = (data) => {
    let value = "";
    if (data == "url") {
      data = "urls";
    }
    value = Type && Type.filter((itm) => itm?.slug == data?.replace("-", " "));
    return value[0]?.type ? value[0]?.type : "--";
  };

  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = data?.all_qr_downloads?.filter((item) =>
    item?.setting?.qrCodeName
      ?.toLowerCase()
      .includes(searchQuery?.toLowerCase())
  );

  return (
    <Layout>
      <div className="text-right">
        <div className="d-flex justify-content-between align-items-center">
          <div class="rowAdd d-flex align-items-center justify-content-between gap-1">
            {" "}
            <span className="d-flex gap-1">
              {" "}
              <Link to="/users">
                <i title='Back'  aria-hidden="true" class=" fa fa-angle-double-left"></i>
              </Link>
              <h2 class="name_heading mb-0">User Detail</h2>{" "}
            </span>{" "}
            <Link to={`/users/edit/${data?.id}`}>
              <span title='Edit'  class="material-icons text-white">border_color</span>
            </Link>
          </div>
          {/* <h2 className="Profilehedding mt-3">User Detail</h2> */}
          {/* <div className='d-flex'>
                        <Link to={`/users/edit/${data?.id}`}><i className="fa fa-pen mr-4 mb-3" title='Edit' aria-hidden="true"></i></Link>
                        <Link to="/users"><i className="fa fa-arrow-left mr-4 mb-3" title='Back' aria-hidden="true"></i></Link>
                    </div> */}
        </div>
      </div>
      <div className="row mt-3">
        <div className="sideclass col-md-12">
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Name</label>
              <div className="profiledetailscls">{data && data.fullName || "--"}</div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Mobile No</label>
              <div className="profiledetailscls">{data && data.mobileNo || "--"}</div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Email</label>
              <div className="profiledetailscls">{data && data.email || "--"}</div>
            </div>

            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Active Plan</label>
              <div className="profiledetailscls">
                {data && data?.my_active_subscription?.name || "--"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">StaticQrcode </label>
              <div className="profiledetailscls">
                {data && data?.my_active_subscription?.staticQrcode || "--"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">DynamicQrcode </label>
              <div className="profiledetailscls">
                {data && data?.my_active_subscription?.dynamicQrcode || "--"}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <label className="profileheddingcls">Max folders </label>
              <div className="profiledetailscls">
                {data && data?.my_active_subscription?.max_folders || "--"}
              </div>
            </div>
            <div className="col-md-12 mb-3">
              <label className="profileheddingcls">Image</label>
              <div>
                <div className="imagethumbWrapper">
                  <img
                    src={methodModel.noImg(data && data.image, "users")}
                    className="thumbnail"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {filteredData?.length > 0 && <div className="mt-3">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search by Campaign Name..."
            className="search_div mb-1 d-block ml-auto"
          />
          <div className="table-responsive mt-3">
            <table className="table table_design">
              {<thead>
                <tr>
                  <th>Campaign Name</th>
                  <th>QR Code ID</th>
                  <th>Type</th>
                  {/* <th scope="col"><span className="table_th">Flyer</span></th>
                                        <th scope="col"><span className="table_th">Print Run</span></th> */}
                  <th>Campaign Start</th>
                  <th>Campaign End</th>
                  <th scope="col">Unique Scans</th>
                  <th>Total Scans</th>
                  <th className="wrap_data">Redirectional Url</th>
                </tr>
              </thead>}
              <tbody>
                {filteredData &&
                  filteredData?.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <span className="table_td">
                            <span className="campaign-name pointer">
                              <span className="blue-text">
                                {" "}
                                {item?.setting?.qrCodeName || "--"}{" "}
                              </span>
                            </span>{" "}
                          </span>
                        </td>

                        <td>{item?.slug || "--"}</td>
                        <td className="text-capitalize">
                          {FindType(item?.type)}
                        </td>
                        {/* <td className="text-capitalize">{item?.setting?.flyer || '--'}</td> */}
                        {/* <td>{item?.setting?.printRun || '--'}</td> */}

                        <td>
                          {item?.setting?.campaignStart
                            ? moment(item?.setting?.campaignStart).format(
                              "MMM DD,YYYY"
                            )
                            : "--"}
                        </td>
                        <td>
                          {item?.setting?.campaignEnd
                            ? moment(item?.setting?.campaignEnd).format(
                              "MMM DD,YYYY"
                            )
                            : "--"}
                        </td>
                        <td>
                          {!item?.staticQrcode
                            ? item?.slug_details?.length || 0
                            : "--"}
                        </td>
                        <td>
                          {!item?.staticQrcode
                            ? totalScansCount(item?.slug_details)
                            : "--"}
                        </td>
                        <td>
                          {FindType(item?.type) == "VCard" ? (
                            <button
                              className="btn btn-primary primary_card "
                              onClick={() => {
                                handleShow();
                                setQrData(item?.qrCode_data);
                              }}
                            >
                              V-Card
                            </button>
                          ) : (
                            <span className="data_row">
                              {item?.qrCode_data?.slice(0, 40)}
                              <button
                                onClick={() =>
                                  copyLinksToClipboard(item?.qrCode_data)
                                }
                              >
                                <FontAwesomeIcon icon={faCopy} />
                              </button>
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>}
        <Modal show={show} onHide={handleClose} className="shadowboxmodal">
          <Modal.Header className="align-items-center" closeButton>
            <h5 className="modal-title">V-Card Data</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="modal_data">
              {qrData}
              <Button variant="primary" onClick={copyToClipboard}>
                Copy Text
              </Button>
              {copied && <span>Copied!</span>}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  );
};

export default UserDetail;
