import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const SupportDetail = () => {
  const { id } = useParams();
  const [detail, setdetail] = useState();
  const history = useHistory();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    loader(true);
    ApiClient.get(`contact?id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res.data);
      }
      loader(false);
    });
  };

  return (
    <Layout>
      <div class="rowAdd d-flex align-items-center justify-content-between gap-1">
        {" "}
        <span class="d-flex gap-1">
          {" "}
          <a     onClick={(e) => {
            history.goBack();
          }}>
            <i
              title="Back"
              aria-hidden="true"
              class=" fa fa-angle-double-left"
            ></i>
          </a>
          <h2 class="name_heading mb-0">Support Email Detail</h2>{" "}
        </span>{" "}

      </div>
   
      <div className="form-row mt-4">
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">FirstName</label>
     
          <div class="profiledetailscls">{detail?.firstName}</div>
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">LastName</label>
     
     <div class="profiledetailscls">{detail?.lastName}</div>
      
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Email</label>
     
     <div class="profiledetailscls">{detail?.email}</div>
      
     
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Contact Number</label>
     
     <div class="profiledetailscls"> {detail?.phone}</div>
 
        </div>
        <div className="col-md-6 mb-3">
        <label class="profileheddingcls">Subject</label>
     
     <div class="profiledetailscls">  {detail?.subject}</div>
    
        </div>
        <div className="col-md-12 mb-3">
        <label class="profileheddingcls">Description</label>
     
     <div class="profiledetailscls">  {detail?.description}</div>
    
       
        </div>
      </div>
    </Layout>
  );
};

export default SupportDetail;
