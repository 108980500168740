import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/global/layout"
import { Link, useHistory } from "react-router-dom";
import ApiClient from "../../methods/api/apiClient";
import SelectDropdown from "../../components/common/SelectDropdown";
import statusModel from "../../models/status.model";
import { toast } from "react-toastify";
import Pagination from "react-pagination-js";
import { useDispatch, useSelector } from "react-redux";
import { search_success } from "../../actions/search";
import moment from "moment";
import loader from "../../methods/loader";

const FAQListing = () => {
    const user = useSelector((state) => state.user)
    const dispatch = useDispatch()
    const searchState = useSelector((state) => state.search)
    const [filter, setfilter] = useState({ page: 1, count: 20, status: '', search: '', page_name: 'home-page', category: '' })
    const [data, setdata] = useState()
    const [total, settotal] = useState(0)
    const [loading, setloading] = useState(false)
    const [category, setcategory] = useState()
    const [deleteId, setdeleteId] = useState()
    const history = useHistory()
    const dragItem = useRef();
    const dragItems = useRef();
    const dragOverItem = useRef();

    useEffect(() => {
        getdata()
        getCategory()
    }, [])

    const getCategory = () => {
        ApiClient.get(`common-category/all?type=faq`).then(res => {
            if (res.success) {
                setcategory(res.data.map(item => {
                    return ({ id: item?._id, name: item?.name })
                }))
            }
        })
    }

    useEffect(() => {
        if (user && user.loggedIn) {
            setfilter({ ...filter, search: searchState.data });
            getdata({ search: searchState.data, page: 1 });
        }
    }, [searchState]);

    const getdata = (p = {}) => {
        let fil = { ...filter, ...p, sortBy: 'srNumber asc' }
        setloading(true)
        ApiClient.get(`faq/all`, fil).then(res => {
            if (res.success) {
                setdata(res.data.data)
                settotal(res.data.total)
            }
            setloading(false)
        })
    }

    const handleFilters = (p = {}) => {
        setfilter({ ...filter, ...p })
        getdata(p)
    }

    const pageChange = (e) => {
        setfilter({ ...filter, page: e });
        getdata({ page: e });
    }

    const statusChange = (item) => {
        let status = 'active';
        if (item.status == 'active') status = 'deactive';
        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            setloading(true);
            ApiClient.put(`change/status?model=faq&status=${status}&id=${item?.id}`).then((res) => {
                if (res.success) {
                    toast.success(res.message)
                    getdata();
                }
                setloading(false);
            }
            )
        }
    }

    const handleAction = (id, key) => {
        if (key == 'view') {
            history.push(`/faq/detail/${id}`)
        } else if (key == 'edit') {
            history.push(`/faq/edit/${id}`)
        } else {
            ApiClient.delete(`faq?id=${id}`).then(res => {
                if (res.success) {
                    toast.success(res.message)
                    getdata()
                    document.getElementById('CloseDeleteModal').click()
                }
            })
        }
    }

    const clearAllFilters = () => {
        let p = { page: 1, count: 20, search: '', status: '', qr_code_type_id: '', category: '' }
        setfilter({ ...filter, ...p })
        dispatch(search_success(''))
        getdata(p)
    }

    // Drag & Drop 
    const dragStart = (e, position) => {
        dragItem.current = position;
    };
    const dragEnter = (e, position) => {
        dragOverItem.current = position;
        const copyListItems = [...data];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItems.current = copyListItems
    };
    const drop = (e) => {
        const copyListItems = [...data];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setdata([...copyListItems]);

        let orderData = copyListItems.map((item, i) => {
            return {
                id: item?.id,
                srNumber: i,
            }
        })
        loader(true)
        ApiClient.put(`editFaqSrNum`, orderData).then(res => {
            if (res.success) {
                toast.success('Order Updated.')
            }
            loader(false)
        })
    };

    return (
        <>
            <Layout>
                <div className="d-flex justify-content-between">
              
                    <h3 class="hedding">FAQ</h3>
                    <div className="d-flex">
                        <Link to={`/faq/add`} className="btn btn-primary">Add FAQ</Link>
                        <span className="mx-2">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select Status"
                                className="mr-2"
                                intialValue={filter.status}
                                result={e => handleFilters({ status: e.value })}
                                options={statusModel.list}
                            />
                        </span>
                        <span className="mx-2">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="All Categoryies"
                                className="mr-2"
                                intialValue={filter.category}
                                result={e => handleFilters({ category: e.value })}
                                options={category}
                            />
                        </span>
                        {filter?.status || filter?.search || filter?.category ?
                            <button className="btn btn-secondary ml-2" onClick={e => clearAllFilters()}>Reset</button>
                            : null}
                    </div>
                </div>
             <div className="table-responsive mt-3">
             <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Question</th>
                            <th scope="col">Date</th>
                            <th scope="col">Category</th>
                            <th scope="col">Status</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    {loading ? null :
                        <tbody>
                            {data && data.map((item, index) => {
                                return <tr onDragStart={(e) => dragStart(e, index)} onDragEnter={(e) => dragEnter(e, index)} onDragEnd={e => drop(e)} key={index} draggable>
                                    <td scope="row" className="text-truncate pointer" style={{ maxWidth: '150px' }} onClick={e => handleAction(item?.id, 'view')}>{item?.question}</td>
                                    <td>{moment(item?.createdAt).format('DD MMM YYYY')}</td>
                                    <td>{item?.category_details?.name || '--'}</td>
                                    <td>
                                        <div className={`user_hours ${item.status}`} onClick={() => statusChange(item)}>
                                            <span className='text-capitalize pointer'>
                                                {item.status == 'deactive' ? 'inactive' : 'active'}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="action_icons">
                                            <a className='edit_icon pointer' title="View" onClick={e => handleAction(item?.id, 'view')}>
                                                <i class="material-icons view" title="View">visibility</i>
                                            </a>
                                            <a className='edit_icon pointer' title="Edit" onClick={e => handleAction(item?.id, 'edit')}>
                                                <i class="material-icons edit" title="Edit">edit</i>
                                            </a>
                                            <span className='edit_icon pointer' onClick={e => setdeleteId(item?.id)} data-toggle="modal" data-target="#exampleModal">
                                                <i class="material-icons delete text-danger" title='Delete'> delete</i>
                                            </span>
                                            <span className='edit_icon pointer ml-2'>
                                                <i class="material-icons delete" title='Drag'> pan_tool</i>
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    }
                </table>
             </div>
                {!loading && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>}
                {
                    !loading && total > filter.count ? <div className='paginationWrapper'>
                        <span>Show {filter.count} from {total} records</span>
                        <Pagination
                            currentPage={filter.page}
                            totalSize={total}
                            sizePerPage={filter.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }
                {loading ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}
            </Layout>

            {/* Delete Modal */}
            <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-body">
                            <button type="button" class="close" id="CloseDeleteModal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='text-center mt-4'>
                                <p>Are you sure you want to delete this?</p>
                                <button type="button" class="btn btn-danger" onClick={e => handleAction(deleteId, 'delete')}>Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQListing