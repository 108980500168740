import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import Select from "react-select"

const AddEditLogo = () => {
    const { id } = useParams()
    const [form, setform] = useState({ title: '', qr_code_type_id: '', image: '' })
    // const [categorys, setcategorys] = useState()
    const [qrcodetype, setqrcodetype] = useState()
    const history = useHistory()

    const uploadImage = (e) => {
        setform({ ...form, baseImg: e.target.value })
        let files = e.target.files
        let file = files.item(0)
        loader(true)
        ApiClient.postFormData('upload/image?modelName=logo', { file: file, modelName: 'logo' }).then(res => {
            if (res.success) {
                let image = res.data.fullpath
                setform({ ...form, image: image, baseImg: '' })
            } else {
                setform({ ...form, baseImg: '' })
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            ApiClient.get(`logo?id=${id}`).then(res => {
                if (res.success) {
                    setform(res.data)
                    getQrcodetype(res.data)
                    // getCategory(res.data)
                }
            })
        } else {
            getQrcodetype('')
            // getCategory('')
        }
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!form?.image) {
            toast.error('Image is required')
            return
        }
        let payload = {
            ...form,
            // category_id: form?.category_id?.value,
            qr_code_type_id: form?.qr_code_type_id?.value
        }
        if (!id) {
            ApiClient.post(`logo`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/logo`)
                }
            })
        } else {
            payload = {
                ...payload,
                id: id
            }
            ApiClient.put(`logo`, payload).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/logo`)
                }
            })
        }
    }

    const getQrcodetype = (data) => {
        ApiClient.get(`qrtype/all?status=active`).then(res => {
            if (res.success) {
                setqrcodetype(res.data.map(item => {
                    return ({ value: item?._id, label: item?.type })
                }))
                if (data) {
                    let values = { ...data }
                    console.log(data,'=Data=');
                    console.log(res.data,'==Resdata==');
                    res.data.filter(itm => {
                        if (itm._id == data?.qr_code_type_id?.id) {
                            values.qr_code_type_id = { value: itm?._id, label: itm?.type }
                        }
                    })
                    setform(values)
                }
            }
        })
    }

    // const getCategory = (data) => {
    //     if(id) loader(true)
    //     ApiClient.get(`common-category/all`).then(res => {
    //         if (res.success) {
    //             setcategorys(res.data.map(item=>{
    //                 return ({ value: item?._id, label: item?.name })
    //             }))
    //             if(data){
    //                 let values = { ...data }
    //                 res.data.filter(itm=>{
    //                     if(itm._id == data?.category_id?.id){
    //                         values.category_id = { value:itm?._id, label: itm?.name }
    //                     }
    //                 })
    //                 setform(values)
    //             }
    //         }
    //         if(id) loader(false)
    //     })
    // }

    return (
        <>
            <Layout>
            <div class="rowAdd d-flex "> <h2 class="name_heading mb-0"> {!id ? 'Add' : 'Edit'} Logo</h2> </div>

                <form className="mt-5" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Title <span className="text-danger">*</span></label>
                            <input type='text' value={form?.title} onChange={e => setform({ ...form, title: e.target.value })} className="form-control" placeholder="Enter Title" required />
                        </div>
                        <div className="col-md-6">
                            <label>Qr Code Type <span className="text-danger">*</span></label>
                            <Select
                                value={form?.qr_code_type_id}
                                onChange={e => { setform({ ...form, qr_code_type_id: e }) }}
                                options={qrcodetype}
                                required
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <label>Image <span className="text-danger">*</span></label>
                            <div className='maininutcls mt-2'>
                                <label className="profileImageLabel">
                                    {form?.image ?
                                        <img src={`${environment?.api}images/logo/${form?.image}`} className="profileImage" />
                                        :
                                        <img src='/assets/img/placeholder.png' className="profileImage" />
                                    }
                                </label>
                                <div className='profile_btn'>
                                    <div>
                                        <label className="btn btn-primary edit ml-3">
                                            <input id="bannerImage" type="file" className="d-none" accept="image/*" value={form.baseImg ? form.baseImg : ''} onChange={(e) => { uploadImage(e); }} />{form.image ? 'Change' : 'Upload'} Image</label>
                                    </div>
                                    <div>
                                        {form.image ? <label className="btn btn-primary  delete ml-3" onClick={e => setform({ ...form, image: "" })}>Remove Image</label> : <></>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 text-right">
                        <button type="button" className="btn btn-secondary discard mr-2" onClick={e => { history.goBack() }}>Back</button>
                        <button type="submit" className="btn btn-primary ml-2">{!id ? 'Save' : 'Update'}</button>
                    </div>
                </form>
            </Layout>
        </>
    )
}

export default AddEditLogo