import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './style.scss';


const Forgotpassword = () => {
    const user = useSelector(state => state.user)
    const [form, setForm] = useState({ email: '' })
    const history = useHistory()

    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/dashboard')
        }
    }, [])

    const hendleSubmit = (e) => {
        e.preventDefault()
        loader(true)
        ApiClient.post('forgot/password', form).then(res => {
            if (res.success) {
                setTimeout(() => {
                    toast.success(res.message)
                }, 100);
                history.push(`/login`);
            }
            else{
                toast.error(res.error.message)
            }
            loader(false)
        })
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-4 px-0">
                        <div className='banner_img'>
                            <div className='loginLeftImg'>
                                {/* <h3>The multipurpose tool you need to succeed in business</h3> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 p-0">
                        <div className='right_side'>
                            <form className="centerLogin" onSubmit={hendleSubmit}>
                                <div className="text-center mb-4 d-flex align-items-center gap-2">
                                <i class="fa fa-angle-double-left" aria-hidden="true" onClick={e=>{ history.goBack() }}></i>
                                    <h3 className="text-left lgtext m-0">Forgot password?</h3>
                                  
                                </div>
                                <div className="mb-3">
                                    <div className="inputWrapper">
                                        <input
                                            type="email"
                                            className="form-control mb-0 bginput" placeholder='Email'
                                            value={form.email}
                                            required
                                            onChange={e => setForm({ ...form, email: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="buttons">
                                    <button type="submit" className="btn btn-primary loginclass mb-4">
                                        Send Verification Code
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ToastContainer position="top-right" />
            </div>
        </>
    );
};

export default Forgotpassword;
