// import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Select from "react-select"

const AddEditFAQ = () => {
    const { id } = useParams()
    const [form, setform] = useState({ question: '', answer: '', page_name: 'home-page', category: '' })
    const [tinymce, settinymce] = useState('')
    const [category, setcategory] = useState()
    const history = useHistory()

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!form?.answer) {
            toast.error('Answer is required')
            return
        }
        if (!form?.category) {
            toast.error('Category is required')
            return
        }
        if (!id) {
            ApiClient.post(`faq`, { ...form, category: form?.category?.value }).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/faq`)
                }
            })
        } else {
            ApiClient.put(`faq`, { question: form?.question, answer: form?.answer, category: form?.category?.value , id: id, page_name: 'home-page' }).then(res => {
                if (res.success) {
                    setTimeout(() => {
                        toast.success(res.message)
                    }, 100);
                    history.push(`/faq`)
                }
            })
        }
    }

    useEffect(() => {
        if (id) getDetail()
        getCategory()
    }, [])

    const getCategory = () => {
        ApiClient.get(`common-category/all?type=faq`).then(res => {
            if (res.success) {
                setcategory(res.data.map(item => {
                    return ({ value: item?._id, label: item?.name })
                }))
            }
        })
    }

    const getDetail = () => {
        loader(true)
        ApiClient.get(`faq?id=${id}`).then(res => {
            if (res.success) {
                let data = res.data
                data.category = { value:data?.category?._id, label: data?.category?.name }
                setform(data)
                settinymce(res?.data?.answer)
            }
            loader(false)
        })
    }

    return (
        <Layout>
            <div class="rowAdd d-flex"> <h2 class="name_heading mb-0"> {!id ? 'Add' : 'Edit'} FAQ</h2> </div>
    
            <form className="mt-4" onSubmit={handleSubmit}>
                <div className="form-row">
                    <div className="col-md-12">
                        <label>Question</label>
                        <input type='text' value={form?.question} maxLength="100" onChange={e => setform({ ...form, question: e.target.value })} className="form-control" placeholder="Enter Question" required />
                    </div>
                    <div className="col-md-12 mt-3">
                        <label>Answer</label>
                        <div className="rounded-5 descript_editpro">
                        <ReactQuill
                               theme="snow"
                                value={form?.answer || tinymce}
                                onChange={(newValue) =>{
                                     setform({ ...form, answer: newValue })}}
                            /></div>
                        {/* <Editor
                            apiKey={environment?.tinymceApiKey}
                            initialValue={tinymce}
                            onEditorChange={(newValue, editor) => setform({ ...form, answer: newValue })}
                            required
                            init={{
                                height: 300,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                ],
                                toolbar: 'undo redo | formatselect | ' +
                                    'bold italic backcolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                        /> */}
                        {/* <textarea value={form?.answer} onChange={e => setform({ ...form, answer: e.target.value })} className="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Answer" required></textarea> */}
                    </div>
                    <div className='col-md-12 mt-3'>
                        <label>Category</label>
                        <Select
                            value={form?.category}
                            onChange={e => { setform({ ...form, category: e }) }}
                            options={category}
                            required
                        />
                    </div>
                </div>
                <div className="text-right mt-5">
                    <button type="button" className="btn btn-secondary discard mr-2" onClick={e => { history.goBack() }}>Back</button>
                    <button type="submit" className="btn btn-primary ml-2">{!id ? 'Save' : 'Update'}</button>
                </div>
            </form>
        </Layout>
    )
}

export default AddEditFAQ