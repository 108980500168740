import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const BlogDetail = () => {
  const [detail, setdetail] = useState();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = () => {
    loader(true);
    ApiClient.get(`blog?id=${id}`).then((res) => {
      if (res.success) {
        setdetail(res.data);
      }
      loader(false);
    });
  };

  return (
    <>
      <Layout>
        <div class="rowAdd d-flex align-items-center justify-content-between gap-1">
          {" "}
          <span class="d-flex gap-1">
            {" "}
            <a   onClick={(e) => {
                history.goBack();
              }}>
              <i
                title="Back"
                aria-hidden="true"
                class=" fa fa-angle-double-left"
              ></i>
            </a>
            <h2 class="name_heading mb-0">Blog Detail</h2>{" "}
          </span>{" "}
          <a  onClick={(e) => {
                history.push(`/blog/edit/${id}`);
              }}>
            <span title="Edit" class="material-icons text-white">
              border_color
            </span>
          </a>
        </div>
     
        <div className="form-row mt-4">
          <div className="col-md-6 mb-3">
            <label className="profileheddingcls">
              Author
            
             
            </label>
            <div className="profiledetailscls"> {detail?.author}</div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="profileheddingcls">
              Title
            </label>
            <div className="profiledetailscls">{detail?.heading}</div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="profileheddingcls">
              Description
             
            </label>
            <div className="profiledetailscls"> <span
                dangerouslySetInnerHTML={{ __html: detail?.description }}
              ></span></div>
          </div>
          <div className="col-md-6 mb-3">
            <label className="profileheddingcls">
              Date
            </label>
            <div className="profiledetailscls">{moment(detail?.createdAt).format("DD MMM YYYY")}</div>
          </div>
          <div className="col-md-12">
            <label className="profileheddingcls">
              Image
            
         
            </label>
            <div>
            {detail?.image ? (
                <img
                  src={`${environment?.api}images/blog/${detail?.image}`}
             className="detail_img"
                />
              ) : (
                <img
                  src="/assets/img/placeholder.png"
                  className="detail_img"
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BlogDetail;
