// import { Editor } from "@tinymce/tinymce-react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";

const TermsOfUse = () => {
    const [form, setform] = useState({ heading: '', description: '', editor: '', id: '' })
    const [Heading,setHeading] = useState('')
    const [id,setId] =  useState('')

    const handlePage = (e) => {
        e.preventDefault()
        let payload = {
            heading: form?.heading || Heading,
            description: form?.description,
            type: 'terms_of_use',
            id: form?.id || id,
        }
        // if (!form?.editor) {
        //     toast.error('Description is required')
        //     return
        // }
        loader(true)
        ApiClient.put(`pages`, payload).then(res => {
            if (res.success) {
                toast.success(res.message)
            }
            loader(false)
        })
    }

    useEffect(() => {
        getDetails()
    }, [])

    const getDetails = () => {
        loader(true)
        ApiClient.get(`page?type=terms_of_use`).then(res => {
            if (res.success) {
                setform({
                    heading:res?.data?.heading,
                    description: res?.data?.description,
                    editor: res?.data?.editor,
                    id: res?.data?.id
                })
                setHeading(res?.data?.heading)
                setId(res?.data?.id)
            }
            loader(false)
        })
    }

    return (
        <>
            <Layout>
                <div>
                <h3 class="hedding">Terms Of Use</h3>
                
                </div>
                <form onSubmit={handlePage}>
                <div className="form-row mt-4">
                   
                        <div className="col-md-12 mb-3">
                            <label>Heading</label>
                            <input type='text' value={form?.heading || Heading} onChange={e => setform({ ...form, heading: e.target.value })} className="form-control" placeholder="Heading" required />
                        </div>
                        <div className="col-md-12 mb-3">
                            <label>Description</label>
                            {/* <Editor
                                apiKey={environment?.tinymceApiKey}
                                initialValue={form?.description}
                                onEditorChange={(newValue, editor) => setform({ ...form, editor: newValue })}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'undo redo | formatselect | ' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            /> */}
                            <div className="rounded-5 descript_editpro">
                            <ReactQuill
                                theme="snow"
                                value={form?.description}
                                onChange={(newValue, editor) => setform({ ...form, description: newValue })}
                                
                            /></div>
                        </div>
                        <div className="col-md-12 mt-3 text-right">
                            <button className="btn btn-primary">Update</button>
                        </div>
                 
                </div>
                </form>
            </Layout>
        </>
    )
}

export default TermsOfUse