import React from "react";
import environment from "../../../environment";

const Html = ({ ListItemLink, tabclass, urlAllow, route }) => {
  return (
    <div className="side_flex"><ul className="nav flex-column" component="siderbar">
    <ListItemLink to="/dashboard">
      <i class="material-icons mr-2" title="Dashboard">dashboard</i>
      <span class="side_head">Dashboard </span>
    </ListItemLink>
    <ListItemLink to="/users">
      <i class="material-icons mr-2 w-0" title="Users">people</i>
      <span class="side_head">Users</span>
    </ListItemLink>
    <ListItemLink to="/qrtypes">
      <i class="material-icons mr-2 w-0" title="Qr Code">qr_code</i>
      <span class="side_head">QR Code Type</span>
    </ListItemLink>
    <ListItemLink to="/blog">
      <i class="material-icons mr-2 w-0" title="Blog">book</i>
      <span class="side_head">Blog</span>
    </ListItemLink>
    <ListItemLink to="/logo">
      <i class="material-icons mr-2 w-0" title="Logo">apps</i>
      <span class="side_head">Logo</span>
    </ListItemLink>
    <ListItemLink to="/categories">
      <i class="material-icons mr-2 w-0" title="FAQ Category">category</i>
      <span class="side_head">FAQ Categories</span>
    </ListItemLink>
    <ListItemLink to="/faq">
      <i class="material-icons mr-2 w-0" title="FAQ">quiz</i>
      <span class="side_head">FAQ</span>
    </ListItemLink>
    {/* <ListItemLink to="/feature">
      <i class="material-icons mr-2 w-0" title="Features">rule</i>
      <span class="side_head">Features</span>
    </ListItemLink> */}
    <ListItemLink to="/plan">
      <i class="material-icons mr-2 w-0" title="Plan">lan</i>
      <span class="side_head">Plan</span>
    </ListItemLink>
    <ListItemLink to="/support">
      <i class="material-icons mr-2 w-0" title="Support">help</i>
      <span class="side_head">Support</span>
    </ListItemLink>
    <ListItemLink to="/withdrawrequests">
      <i class="material-icons mr-2 w-0" title="Support">request_page</i>
      <span class="side_head">Withdraw Requests</span>
    </ListItemLink>
    <ListItemLink to="/termsofuse">
      <i class="material-icons mr-2 w-0" title="Terms Of Use">article</i>
      <span class="side_head">Terms Of Use</span>
    </ListItemLink>
    <ListItemLink to="/privacypolicy">
      <i class="material-icons mr-2 w-0" title="Privacy Policy">policy</i>
      <span class="side_head">Privacy Policy</span>
    </ListItemLink>
    <ListItemLink to="/cookiepolicy">
      <i class="material-icons mr-2 w-0" title="Cookie Policy">cookie</i>
      <span class="side_head">Cookie Policy</span>
    </ListItemLink>
  </ul></div>
  );
}

export default Html